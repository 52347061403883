<!--
 * @Description: 车位查询代缴订单详情 payForSpaceParkOnline
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2022-01-10 16:36:19
 * @LastEditors: zhoucheng
-->
<template>
  <div class='body'>
    <van-nav-bar title="订单详情"
                 left-text="返回"
                 left-arrow
                 @click-left="handleClickTopBack" />
    <van-row class="main">
      <van-row class="infoBox">
        <van-row class="infoTitle">
          订单详情
        </van-row>
        <van-row class="infoLine">
          <van-col class="name">车牌号</van-col>
          <van-col class="details">{{orderList.plateNumber}}</van-col>
        </van-row>
        <van-row class="infoLine">
          <van-col class="name">在停地</van-col>
          <van-col class="details">{{orderList.parkName}}</van-col>
        </van-row>
        <van-row class="infoLine">
          <van-col class="name">泊位号</van-col>
          <van-col class="details">{{orderList.parkSpaceNumber}}</van-col>
        </van-row>
        <van-row class="infoLine">
          <van-col class="name">停车时长</van-col>
          <van-col class="details">{{orderList.parkDuration}}分钟</van-col>
        </van-row>
        <van-row class="infoLine">
          <van-col class="name">开始时间</van-col>
          <van-col class="details">{{orderList.entranceTime}}</van-col>
        </van-row>
        <van-row class="infoLine">
          <van-col class="name">计费规则</van-col>
          <van-col class="details"
                   style="color:blue"
                   @click="queryBillingRuleDetail">查看计费规则详情</van-col>
        </van-row>
      </van-row>
      <van-row class="infoBox">
        <van-row class="infoLine"
                 style="margin-bottom:8px">
          <van-col class="name">应收费用</van-col>
          <van-col class="pay">
            <span style="color:#19A9FC;font-size:20px;">{{orderList.receivableMoneyAmount}}</span>
            <span v-if="showFree===2"> - </span>
            <span style="color:red;font-size:20px;"
                  v-if="showFree===2">{{orderList.receivableMoneyAmount}}</span>元
          </van-col>
        </van-row>
      </van-row>
      <van-row>
        <van-button type="info"
                    class="payBtn"
                    @click="handleClickPay">支付</van-button>
      </van-row>
    </van-row>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
      showFree: 1, // 计费规则是否免费 2是免费 1是收费
      orderList: {}, // 订单详情列表
      plateNumber: '', // 路由车牌号
      numberPlateColorCode: '',
      orderSequence: '',
      billingRuleDefId: ''
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.billingRuleDefId = this.$route.query.billingRuleDefId
    this.getOrderList()
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () {

  }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    // 获取订单详情列表
    getOrderList () {
      const info = {
        plateNumber: this.$route.query.plateNumber,
        numberPlateColorCode: this.$route.query.numberPlateColorCode
      }
      this.$payForParkCount.queryOnlineOrderBySinglePlateNumber(info).then(res => {
        this.orderList = res.resultEntity
        this.orderList.receivableMoneyAmount = Number(this.orderList.receivableMoneyAmount / 100).toFixed(2)
        this.orderSequence = res.resultEntity.orderSequence
        this.billingRuleDefId = res.resultEntity.billingRuleDefId
        this.queryForFree()
      })
    },
    // 查询该计费规则是否收费
    queryForFree () {
      const info = {
        billingRuleDefId: this.billingRuleDefId
      }
      this.$billRuleDetail.queryBillingRuleById(info).then(res => {
        // 2是免费 1是收费
        if (res.resultEntity.chargeStatusCode === 2) {
          this.showFree = 2
        } else if (res.resultEntity.chargeStatusCode === 1) {
          this.showFree = 1
        }
      })
    },
    // 查看计费规则详情
    queryBillingRuleDetail () {
      this.$router.push({ name: 'billRuleDetail', query: { billingRuleDefId: this.billingRuleDefId } })
    },
    // 支付按钮
    handleClickPay () {
      // 不收费直接返回，订单结束
      if (this.showFree === 2) {
        const info = {
          orderSequence: this.orderSequence,
          billingRuleDefId: this.billingRuleDefId
        }
        this.$billRuleDetail.completeOrderFree(info).then(res => {
          this.$toast('本单免费')
          this.$router.go(-1)
        })
      } else if (this.showFree === 1) {
        if (this.orderList.receivableMoneyAmount && this.orderSequence) {
          this.$router.push({ name: 'choosePayStyle', query: { payMoney: this.orderList.receivableMoneyAmount, payOrder: this.orderSequence } })
        }
      }
    },
    // 顶部返回按钮
    handleClickTopBack () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  .main {
    height: 100px;
    .infoBox {
      margin: 20px 3.2%;
      background-color: #ffffff;
      width: 93.6%;
      // height: 100px;
      border-radius: 5px;
      padding-top: 12px;
      .infoTitle {
        margin: 7.9px 0 12px 12px;
        font-size: 15px;
        color: #333;
        font-weight: 500;
        font-family: PingFang-SC;
      }
      .line {
        border: 1px dashed #cfcfcf;
        width: 92.8%;
        margin-left: 12px;
      }
      .infoLine {
        height: 33.5px;
        line-height: 33.5px;
        .name {
          display: block;
          width: 103.5px;
          line-height: 33.5px;
          font-size: 15px;
          color: #909090;
          font-weight: 400;
          margin-left: 12px;
        }
        .details {
          display: block;
          width: 100%px;
          line-height: 33.5px;
          font-size: 15px;
          color: #333333;
        }
        .pay {
          display: block;
          width: 200px;
          line-height: 33.5px;
          font-size: 15px;
          color: #333333;
        }
      }
    }
    .payBtn {
      width: 93.6%;
      margin: 0 3.2%;
      border-radius: 5px;
      background: #19a9fc;
      font-size: 17px;
    }
  }
}
</style>
